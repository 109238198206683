@import "../mixins";

.container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    direction: rtl;
    padding: 40px 20px;

    .wrapper {
        width: 100%;
        max-width: 1346px;

        h1 {
            font: normal normal bold 35px/40px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #0d1934;
            margin-bottom: 20px;
        }

        h2 {
            font: normal normal bold 25px/30px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #0d1934;
            margin-bottom: 15px;
        }

        p {
            font: normal normal normal 17px/22px "Open Sans", sans-serif;
            line-height: 1.5;
            letter-spacing: 0px;
            color: #0d1934;
            margin-bottom: 15px;
        }

        .underline {
            text-decoration: underline;
        }
    }
}

// for mobile
@include mobile() {
    .container {
        .wrapper {
            h1 {
                font-size: 28px;
                margin-bottom: 20px;
            }

            h2 {
                font-size: 20px;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
                line-height: 1.4;
                margin-bottom: 10px;
            }
        }
    }
}