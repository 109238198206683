@import "../mixins";
.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .container {
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    margin: 0px 20px;
    background: #ffffff;
    border-radius: 12px;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow-y: auto;

    .close {
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 24px;
      color: #666;
      cursor: pointer;
      z-index: 2;
      transition: color 0.2s;

      &:hover {
        color: #cc1919;
      }
    }

    .printBtn {
      position: absolute;
      left: 16px;
      top: 16px;
      padding: 8px 16px;
      background: #3762ec;
      color: white;
      border: none;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      transition: background 0.2s;
      z-index: 2;

      &:hover {
        background: #2951d1;
      }
    }

    .content {
      padding: 24px;

      .header {
        text-align: center;
        margin-bottom: 32px;
        padding-top: 16px;

        h2 {
          font-size: 28px;
          color: #1a1a1a;
          margin-bottom: 24px;
        }

        .mainDetails {
          display: flex;
          justify-content: center;
          gap: 32px;
          padding: 16px;
          background: #f8f9fa;
          border-radius: 8px;
          margin: 0 -8px;

          .mainDetail {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            .label {
              font-size: 14px;
              color: #666;
            }

            .value {
              font-size: 18px;
              font-weight: 600;
              color: #3762ec;
            }
          }
        }
      }

      .sections {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 24px;
        padding: 0 8px;

        .section {
          h3 {
            font-size: 18px;
            color: #333;
            margin-bottom: 16px;
            padding-bottom: 8px;
            border-bottom: 2px solid #eee;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 8px 12px;
              background: #f8f9fa;
              border-radius: 6px;
              transition: background 0.2s;

              &:hover {
                background: #f3f4f6;
              }

              p:first-child {
                color: #666;
                font-size: 14px;
              }

              p:last-child {
                color: #1a1a1a;
                font-weight: 500;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mainContainer {
    .container {
      margin: 0;
      height: 100vh;
      max-height: 100vh;
      border-radius: 0;

      .content {
        padding: 16px;

        .header {
          margin-bottom: 24px;

          h2 {
            font-size: 24px;
            margin-bottom: 16px;
          }

          .mainDetails {
            flex-direction: column;
            gap: 16px;
            padding: 12px;

            .mainDetail {
              flex-direction: row;
              justify-content: space-between;
              width: 100%;

              .label {
                font-size: 14px;
              }

              .value {
                font-size: 16px;
              }
            }
          }
        }

        .sections {
          grid-template-columns: 1fr;
          gap: 20px;

          .section {
            h3 {
              font-size: 16px;
              margin-bottom: 12px;
            }

            ul li {
              padding: 6px 10px;
              
              p:first-child {
                font-size: 13px;
              }

              p:last-child {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .mainContainer,
  .mainContainer * {
    visibility: visible;
  }

  .mainContainer {
    position: absolute;
    left: 0;
    top: 0;
  }
}
