@import "../../mixins";
.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .graphic {
    position: absolute;
    width: 240px;
    height: 280px;
    top: 20px;
    right: 0px;
  }
  .wrapper {
    width: 100%;
    max-width: 910px;
    padding: 25px 10px;
    display: flex;
    flex-direction: column;
  }
}

// for tablets
@include tablet() {
  .container {
    padding-top: 40px;
    .graphic {
      top: -60px;
    }
  }
}

// for mobile
@include mobile() {
}
