@import "../../mixins";
.container {
  position: relative;
  width: 100%;
  max-width: 498px;
  height: 611px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 25px #12325529;
  border-radius: 10px;
  z-index: 2;
  padding: 30px 12px 0px 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .text {
    h2 {
      font: normal normal bold 35px/40px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #0d1934;
      margin-bottom: 31px;
    }
    p {
      font: normal normal normal 17px/25px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #0d1934;
      width: 100%;
      max-width: 441px;
    }
  }

  img {
    width: 100%;
    max-width: 344px;
    height: 229px;
    
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    // margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid gainsboro;
    padding: 20px;


    a {
      text-decoration: none;
      color: unset;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    height: auto;
    .text {
      h2 {
        font-size: 28px;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
      }
    }
 
  }
}
