@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  font-family: "Open Sans", sans-serif;
  direction: rtl;
  scroll-behavior: smooth;
}

option {
  font-family: "Open Sans", sans-serif !important;
}

:root {
  --primary-color: #1c58da;
  --secondary-color: #1a59da;
}

input,
select,
button {
  outline: none;
}
input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="password"] {
  -webkit-text-size-adjust: none;
}
.input-text {
    width: 225px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #95a5b2;
    border-radius: 5px;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 18px;
    padding-right: 15px;
    padding-left: 15px;
}
button {
  transition: all 300ms ease-in-out;
  &:hover {
    opacity: 0.9;
  }
}

// remove arrows from number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

select, input {
  &:enabled {
    background-color: unset;
  }
}

$mobile-breakpoint: 768px;
@media (max-width: $mobile-breakpoint) {
.hide_on_mobile{
  display: none;
}}

div#google_translate_element {
  padding: 10px 10px 0;
}