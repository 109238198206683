@import "../mixins";
.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  .container {
    width: 100%;
    max-width: 627px;
    // height: 100%;
    height: auto;
    // max-height: 350px;
    max-height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;

    overflow-y: auto;
    padding: 20px 20px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    .close {
      position: absolute;
      right: 20px;
      top: 10px;
      font: normal normal normal 32px/30px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .heading1 {
        font: normal normal bold 25px/34px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: var(--secondary-color);
        margin-bottom: 40px;
      }

      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        margin-top: 30px;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          margin-bottom: 10px;

          .field {
            display: flex;
            flex-direction: column;
            width: 49%;

            label {
              margin-bottom: 8px;
            }
            input {
              height: 40px;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #95a5b2;
              border-radius: 5px;
              padding: 0px 15px;
            }
          }
        }

        button {
          margin-top: 20px;
          width: 100%;
          height: 45px;
          background: #1c58da 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          font: normal normal bold 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}

// for tablet
@include tablet() {
  .mainContainer {
    .container {
      max-height: auto;
      justify-content: center;
      .content {
        height: auto;
        width: auto;
      }
    }
  }
}

// for mobile
@include mobile() {
  .mainContainer {
    .container {
      padding: 20px 10px;
      .close {
        right: 10px;
        top: 10px;
        font-size: 25px;
      }
      .content {
        padding: 20px 0px;
        form {
          .row {
            flex-direction: column;
            .field {
              width: 100%;
              input {
                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
          button {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
