@import "../../mixins";

.container {
  position: relative;
  width: 100%;
  height: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bg {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    max-width: 1287px;
    height: 992px;
    z-index: 1;
  }

  .wrapper {
    width: 100%;
    max-width: 1000px;
    padding: 80px 10px;

    .logo {
      z-index: 2;
      position: relative;
      margin-bottom: 20px;

      img {
        margin-bottom: 5px;
        width: 170px;
      }

      p {
        font: normal normal normal 25px/34px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }

    .cards {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      position: relative;
      z-index: 3;
    }

    .steps {
      margin-top: 30px;
      z-index: 2;
      position: relative;

      h3 {
        font: normal normal bold 35px/40px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #0d1934;
        margin-bottom: 15px;
      }

      p {
        font: normal normal normal 17px/25px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #0d1934;
        max-width: 313px;
        margin-bottom: 12px;
      }

      .btns {
        display: flex;
        align-items: center;
        gap: 5px;

        a {
          text-decoration: none;
          color: unset;
          img {
            width: 145px;
            height: 45px;
          }
        }
      }
    }
  }
}

// for tablets
@include tablet() {
  .container {
    height: auto;

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        img {
          margin-bottom: 10px;
        }
      }

      .cards {
        flex-direction: column;
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    overflow: hidden;

    .bg {
      height: 500px;
      width: 800px;
      left: -240px;
      right: unset;
    }

    .wrapper {
      padding: 50px 10px 60px 10px;
      // padding: 60px 10px;

      .logo {
        img {
          margin-bottom: 10px;
          width: 140px;
          // width: 240px;
          // height: 37px;
        }

        p {
          font-size: 20px;
          text-align: center;
        }
      }

      .steps {
        margin-top: 30px;

        h3 {
          font-size: 28px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}