.outer {
  margin: auto;
  margin-top: 15%;
  background: #ffffff;
  height: 100px;
  width: 100px;
  border-radius: 15px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999999;

  .middle {
    height: 60px;
    width: 60px;
    margin: auto;
    position: relative;
    top: 20px;
    border-radius: 50%;
    background-image: linear-gradient(
        150deg,
        transparent 50%,
        var(--primary-color) 50%
      ),
      linear-gradient(90deg, var(--primary-color) 50%, white 50%);
    -webkit-animation: rotation 1200ms infinite linear;
    animation: rotation 1200ms infinite linear;
    transform-origin: 50% 50%;
    animation-timing-function: ease;

    .inner {
      background: #ffffff;
      height: 48px;
      width: 48px;
      margin: auto;
      position: relative;
      top: 6px;
      border-radius: 50%;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(270deg);
  }

  to {
    transform: rotate(630deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }

  to {
    -webkit-transform: rotate(630deg);
  }
}

.container {
  width: 100%;
  max-width: 1041px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  font: normal normal normal 17px/20px "Open Sans", sans-serif;

  .vehicles {
    display: flex;
    align-items: center;
    gap: 10px;

    .tab {
      width: 150px;
      height: 40px;
      background: #d5e1f2 0% 0% no-repeat padding-box;
      border-radius: 23px;
      font: normal normal normal 18px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 2px solid #d5e1f2;

      img {
        margin: 0px 15px;
      }
    }

    .active {
      background: #aec5e5 0% 0% no-repeat padding-box;
      border: 2px solid #1a59da;
      border-radius: 23px;
    }

    .disable {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .vehiclesMobile {
    display: none;
  }

  .infoBtn {
    width: 184px;
    height: 40px;
    background: #486db9 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font: normal normal normal 18px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    // &:hover ~ .dropCard {
    //   display: flex;
    // }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .infoBtnMobile {
    display: none;
    align-self: center;
  }

  .dropCard {
    position: absolute;
    left: -320px;
    top: 42px;
    width: 500px;
    height: 450px;
    z-index: 999999;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    span {
      position: absolute;
      font-size: 12px;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }

    ul {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
      gap: 2px;

      li {
        width: 47%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 12px;

          &:nth-child(1) {
            font-weight: 600;
          }

          &:nth-child(2) {
            color: #555;
          }
        }
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    position: relative;
  }

  .card {
    width: 100%;
    height: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .curved {
      transform: translateY(-65px);
      width: 334px;
      height: 51px;
    }

    .licenseContainer {
      display: flex;
      align-items: center;
      gap: 5px;
      transform: translate(-40px, -105px);
      margin-bottom: 20px;
      margin-bottom: -85px;
      width: fit-content;
      .licenseNum {
        width: 195px;
        height: 45px;
        background: #ffca11 0% 0% no-repeat padding-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .wrapper {
          width: 216px;
          height: 45px;
          border: 2px solid #000000;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            input {
              height: 33px;
              width: 100%;
              padding: 0px 10px;
              font: normal normal bold 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              background-color: transparent;
              border: none;
              text-align: center;

              &::placeholder {
                text-align: center;
              }
            }
          }

          .right {
            width: 21px;
            height: 44px;
            padding: 5px;
            background: #165492 0% 0% no-repeat padding-box;
            border-radius: 2px 0px 0px 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font: normal normal normal 18px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }
      button {
        width: 184px;
        width: auto;
        padding: 0px 15px;
        height: 40px;
        background: var(--primary-color) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        font: normal normal normal 18px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        margin: 0px;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:enabled ~ .dropCard {
          display: flex;
        }
      }
    }

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      gap: 9px;

      select,
      input {
        width: 135px;
        height: 45px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        padding: 0px 10px;
        font: normal normal normal 17px/20px "Open Sans", sans-serif;

        color: #000000 !important;

        &:disabled {
          cursor: not-allowed;
        }

        &::placeholder {
          color: #000000 !important;
        }
      }
    }

    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      position: relative;
      border-top: 1px solid #969696;
      padding: 20px 0px;

      input {
        padding: 15px;
        width: 100%;
        height: 45px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        font: normal normal normal 18px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        z-index: 1;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        &:not(:placeholder-shown) {
          & ~ .dropdown {
            display: block;
          }

          & ~ .searchBtn {
            display: block;
          }
        }

        &:focus {
          border: 2px solid #81a5f4;
          box-shadow: #9bb8f5 0px 2px 8px 0px;
        }

        &:focus + .searchBtn {
          display: block;
        }
      }

      .searchBtn {
        // display: none;
        width: 100%;
        max-width: 144px;
        height: 45px;
        background: #1c58da 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
        font: normal normal bold 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        cursor: pointer;

        &:active {
          display: block;
        }
      }

      .dropdown {
        z-index: 999999;
        position: absolute;
        right: 0px;
        top: 65px;
        width: calc(100% - 154px);
        height: auto;
        max-height: 200px;
        overflow-y: auto;
        background-color: #ffffff;
        border: 1px solid #95a5b2;
        border-top: 0px;
        border-radius: 5px;
        display: none;

        ul {
          padding: 15px;
          list-style-type: none;
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            font: normal normal normal 18px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
            // border-bottom: 1px solid #95a5b2;
            padding-left: 5px;
            padding: 0px 0px 5px 5px;
            width: fit-content;
            cursor: pointer;
            transition: all 300ms ease-in-out;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        &::-webkit-scrollbar {
          width: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #ebebeb;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgb(209, 209, 209);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .container {
    font: normal normal normal 15px/20px "Open Sans", sans-serif;

    .top {
      .dropCard {
        left: 0px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    .card {
      .curved {
        display: none;
      }
      .licenseContainer {
        transform: none;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .vehiclesDesktop {
      display: none;
    }
    .vehiclesMobile {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
    .infoBtnDesktop {
      display: none;
    }
    .infoBtnMobile {
      display: flex;
    }
    .infoBtn {
      margin: 0px;
      margin-bottom: 15px;
    }

    .dropCard {
      left: 0;
      right: 0;
      margin: auto;
      top: 135px;
    }

    .vehicles {
      .tab {
        width: 125px;
        height: 35px;
        font: normal normal normal 13px/18px "Open Sans", sans-serif;

        img {
          margin: 0px 15px;
          width: 25px;
        }
      }
    }

    .top {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .card {
      .licenseContainer {
        transform: none;
        margin-bottom: 15px;
        align-self: center;
      }

      .row {
        select,
        input {
          width: 48%;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container {
    .dropCard {
      width: 100%;
      max-width: 400px;
      padding: 10px;
      padding-top: 25px;
      height: auto;

      ul {
        li {
          width: 49%;
          max-width: 170px;
          flex-wrap: wrap;

          p {
            word-wrap: break-word;
            max-width: 100px;
          }
        }
      }
    }
    .top {
    }
  }
}

@media screen and (max-width: 450px) {
  .container {
    .infoBtn {
      margin-right: 0px;
    }

    .vehicles {
      align-items: center;

      .tab {
        width: 100px;
        height: 35px;
        font: normal normal normal 13px/18px Open Sans, sans-serif;

        letter-spacing: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 2px solid #d5e1f2;

        img {
          margin: 0px 12px;
        }
      }
    }

    .top {
      align-items: center;
    }

    .card {
      .row {
        input,
        select {
          width: 48%;
        }
      }

      .search {
        input {
          font: normal normal normal 13px/18px "Open Sans", sans-serif;
        }

        .searchBtn {
          max-width: 110px;
          font: normal normal bold 13px/18px "Open Sans", sans-serif;
        }

        .dropdown {
          width: calc(100%);
        }
      }
    }
  }
}
