.mainContainer {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99999999999999;

  .container {
    z-index: 999999999999;
    width: 278px;
    height: 100vh;
    background: #ffffff;
    box-shadow: 0px 14px 34px rgba(162, 162, 165, 0.4);
    padding: 32px;

    .logo {
      margin-bottom: 60px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      li {
        list-style-type: none;
      }
    }

    .btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-top: 40px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        height: 35px;
        border-radius: 5px;
        border: none;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        cursor: pointer;

        &:nth-child(1) {
          color: #ffffff;
          background: var(--secondary-color) 0% 0% no-repeat padding-box;
        }
        &:nth-child(2) {
          border: 1px solid var(--secondary-color);
          background-color: transparent;
          color: var(--secondary-color);
        }
      }
    }
  }
}
