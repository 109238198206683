@import "../mixins";

.container {
  width: 100%;
  position: relative;

  .wrapper {
    padding: 60px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .card {
      width: 100%;
      max-width: 1646px;
      min-height: 721px;
      height: auto;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 20px 30px 20px;

      h2 {
        font: normal normal bold 35px/40px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #0d1934;
        margin-bottom: 35px;
      }

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        margin-bottom: 30px;

        input {
          width: 100%;
          max-width: 450px;
          height: 45px;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          margin-left: 10px;
          padding: 0px 10px;
        }

        button {
          width: 72px;
          height: 45px;
          background: #1c58da 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }

      .licenseMainContainer {
        // border-right: 1px solid #828282;
        padding: 10px;
        margin: 10px;
        display: flex;
        flex-direction: column;

        h2 {
          margin-bottom: 0;
          font-size: 18px;
        }
        .licenseContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          margin-bottom: 30px;
          position: relative;

          .licenseRight {
            display: flex;
            align-items: center;
            gap: 10px;
            .licenseNum {
              width: 225px;
              height: 45px;
              background: #ffca11 0% 0% no-repeat padding-box;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              .licenseWrapper {
                width: 225px;
                height: 45px;
                border: 2px solid #000000;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                  input {
                    height: 33px;
                    width: 100%;
                    padding: 0px 10px;
                    font: normal normal bold 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    background-color: transparent;
                    border: none;
                    text-align: center;
                    &::placeholder {
                      text-align: center;
                    }
                  }
                }

                .right {
                  width: 21px;
                  height: 33px;
                  height: 43px;
                  padding: 5px;
                  background: #165492 0% 0% no-repeat padding-box;
                  border-radius: 2px 0px 0px 2px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  font: normal normal normal 15px/20px "Open Sans", sans-serif;
                  letter-spacing: 0px;
                  color: #ffffff;
                }
              }
            }
            button {
              background-color: var(--primary-color);
            }
          }

          button {
            width: 184px;
            width: auto;
            padding: 0px 15px;
            height: 40px;
            background: #486db9 0% 0% no-repeat padding-box;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #ffffff;
            margin: 0px;

            &:disabled {
              cursor: not-allowed;
            }

            // &:hover:enabled ~ .dropCard {
            //   display: flex;
            // }
          }

          .my-select {
            &:focus {
              outline: 2px solid blue; /* Change this to your desired highlight style */
            }
          }

          .dropCard {
            position: absolute;
            left: 0px;
            top: 42px;
            width: 500px;
            height: auto;
            z-index: 999999999999;

            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            flex-direction: column;
            padding: 20px;

            span {
              position: absolute;
              right: 5px;
              top: 5px;
              cursor: pointer;
              font-size: 16px;
            }

            ul {
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              list-style: none;
              gap: 1px;

              li {
                width: 47%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                  font-size: 12px;

                  &:nth-child(1) {
                    font-weight: 600;
                  }
                  &:nth-child(2) {
                    color: #555;
                  }
                }
              }
            }
          }
        }
      }

      .refreshMob {
        width: 100%;
        display: none;
        justify-content: flex-end;

        button {
          padding: 10px 10px;
          background: #1c58da 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          font: normal normal normal 14px/14px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
          cursor: pointer;
        }
      }

      .mainTable {
        width: 100%;
        min-width: 1200px;
        // border-collapse: separate;
        border-spacing: 0 0px;
        margin-bottom: 10px;

        thead {
          tr {
            th {
              padding-bottom: 15px;
              text-align: right;
              font: normal normal bold 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;

              &:nth-child(4),
              &:nth-child(5) {
                white-space: nowrap;
              }

              button {
                padding: 10px 10px;
                background: #1c58da 0% 0% no-repeat padding-box;
                border-radius: 5px;
                border: none;
                font: normal normal normal 14px/14px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }

        tbody {
          .noRecords {
            padding: 30px 0px;
            height: 500px;

            td {
              height: 100%;

              h2 {
                font-size: 28px;
                margin-bottom: 0px;
              }
            }
          }

          tr {
            background: #f6f6f6 0% 0% no-repeat padding-box;
            height: 46px;

            td {
              text-align: right;
              font: normal normal normal 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;

              border-left: 1px solid #a9a9a9;
              border-top: 1px solid #a9a9a9;
              width: 11.111%;
              padding: 5px 10px;

              // input field
              .inputField {
                width: 60px;
                height: 25px;
                border: 1px solid #95a5b2;
                padding: 0px 5px;
              }

              // price error
              .fieldError {
                border: 1px solid red;
              }

              // price error text
              .fieldErrorText {
                color: red;
                font-size: 11px;
                line-height: 100%;
              }

              // license number
              .licenseWrapper {
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                  width: 20px;
                }
              }

              // part
              .part {
                display: flex;
                align-items: center;
                font-weight: bold !important;

                img {
                  width: 30px;
                  min-width: 30px;
                  height: 30px;
                  min-height: 30px;
                  border-radius: 10px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }

              // first item
              &:first-child {
                text-align: center;
                width: 50px;
                border-right: 1px solid #a9a9a9;
              }

              // delete button and submit button column
              &:nth-child(11),
              &:nth-child(12) {
                text-align: center;
                width: 50px;

                img {
                  cursor: pointer;
                }
              }

              // last item
              &:last-child {
                padding: 10px;
                text-align: center;

                button {
                  width: 120px;
                  height: 30px;
                  background: #3762ec 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                  border: none;
                  font: normal normal normal 14px/19px "Open Sans", sans-serif;
                  letter-spacing: 0px;
                  color: #ffffff;
                  cursor: pointer;
                }
              }
            }

            .license {
              cursor: pointer;
            }

            // first item
            &:last-child {
              td {
                border-bottom: 1px solid #a9a9a9;
              }
            }

            &:nth-child(even) {
              background: #eaeef0 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 15px;

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          list-style: none;
          width: 27px;
          height: 27px;
          background-color: #ffffff;
          border-radius: 3px;
          box-shadow: 0px 1px 3px #00000029;
          cursor: pointer;
        }

        .active {
          background-color: #3762ec;
          color: #ffffff;
        }
      }

      button {
        background-color: #3762ec;
        color: #ffffff;
        border: none;
        width: 40px;
        height: 27px;
        border-radius: 3px;
        box-shadow: 0px 1px 3px #00000029;
        cursor: pointer;
      }
    }

    .mobile {
      display: none;
    }

    .allArchiveBtn {
      padding: 15px 15px;
      background: #1c58da 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      font: normal normal normal 15px/15px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
      margin-top: 15px;
    }
  }

  // ARCHIVE MODAL
  .archiveModalMainContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

    .archiveModalContainer {
      width: 100%;
      height: 100%;

      max-width: 450px;
      max-height: 280px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 10px;

      overflow-y: auto;
      padding: 20px 20px;
      margin: 0px 20px;
      display: flex;
      flex-direction: column;
      position: relative;

      .close {
        position: absolute;
        right: 20px;
        top: 10px;
        font: normal normal normal 32px/30px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        cursor: pointer;
      }

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          text-align: center;
          font-size: 20px;
          margin-bottom: 25px;
          max-width: 300px;
          line-height: 34px;
        }

        .btns {
          display: flex;
          align-items: center;
          gap: 15px;

          button {
            padding: 15px 25px;
            background: #1c58da 0% 0% no-repeat padding-box;
            border-radius: 5px;
            border: none;
            font: normal normal normal 15px/15px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #ffffff;
            cursor: pointer;

            &:nth-child(2) {
              background-color: #d70040;
            }
          }
        }
      }
    }
  }

  .popupContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

    .popup {
      width: 100%;
      height: 100%;
      max-width: 538px;
      max-height: 538px;
      margin: 0px 20px;
      position: relative;
      border-radius: 10px;

      span {
        position: absolute;
        right: -20px;
        top: -40px;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
      }
    }
  }
}

// for mobile
@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      flex-direction: column;
      gap: 30px;

      .card {
        min-height: auto;

        .licenseMainContainer {
          border: none;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          .licenseContainer {
            display: flex;
            flex-direction: column;
            gap: 0px;

            .licenseRight {
              margin-bottom: 10px;
              .licenseNum {
                max-width: 220px;
                width: 100%;
              }
            }
            button {
              max-width: 220px;
              width: 100%;
            }
            .dropCard {
              width: 100%;
              max-width: 400px;
              max-height: 350px;
              overflow-y: auto;
              padding: 15px;
              top: 100px;
              ul {
                display: flex;
                flex-direction: column;
                li {
                  width: 100%;
                  max-width: auto;
                  flex-wrap: wrap;
                  p {
                    word-wrap: break-word;
                    max-width: 100px;
                  }
                }
              }
            }
          }
        }
        .search {
          flex-direction: column;
        }
        .refreshMob {
          display: flex;
        }

        .mainTable {
          display: none;
        }
      }

      .hideonmobile {
        display: none;
      }

      // mobile cards
      .mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        width: 100%;

        .itemCard {
          width: 100%;
          max-width: 500px;
          height: auto;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 10px;
          display: flex;
          justify-content: space-between;
          gap: 5px;

          .right {
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 20px 10px 20px 0px;

            .partName {
              font-size: 16px;
              max-width: 150px;
            }

            .item {
              display: flex;
              align-items: baseline;
              gap: 5px;

              h3 {
                font-size: 14px;
                white-space: nowrap;
              }

              img {
                width: 50px;
                height: 50px;
                border-radius: 5px;
              }

              p {
                font-size: 14px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 6px;
                max-width: 100px;
                line-height: 15px;

                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 5px;
                }
              }
            }

            .imgItem {
              flex-direction: column;
            }
          }

          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            background-color: rgba(203, 203, 203, 0.186);
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 20px 10px;

            .box {
              width: 100%;
              height: auto;
              padding: 10px 5px;
              background: #e4ebff 0% 0% no-repeat padding-box;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-left: 20px;

              h3 {
                margin-bottom: 10px;
              }

              input {
                width: 100%;
                max-width: 153px;
                height: 35px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #b1b1b1;
                border-radius: 5px;
                font: normal normal normal 13px/18px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #6e6e6e;
                padding: 0px 10px;
                margin-bottom: 10px;
              }

              // price error text
              .fieldErrorText {
                color: red;
                font-size: 11px;
                transform: translateY(-8px);
              }

              button {
                width: 100%;
                max-width: 153px;
                height: 35px;
                background: #19ad79 0% 0% no-repeat padding-box;
                border-radius: 5px;
                border: none;
                font: normal normal normal 15px/20px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #ffffff;
                cursor: pointer;
              }
            }

            .rejectBtn {
              width: 100%;
              height: 35px;
              background: #ffb2b2 0% 0% no-repeat padding-box;
              border-radius: 5px;
              border: none;
              font: normal normal normal 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              cursor: pointer;
            }

            .archiveBtn {
              width: 100%;
              max-width: 100%;
              height: 35px;
              background: #3762ec 0% 0% no-repeat padding-box;
              border-radius: 5px;
              border: none;
              font: normal normal normal 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }

    .archiveModalMainContainer {
      .archiveModalContainer {
        max-width: 450px;
        max-height: 220px;

        .close {
          font-size: 22px;
        }

        .content {
          p {
            font-size: 18px;
            max-width: 250px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

