@import "../../mixins";
.container {
  width: 326px;
  min-width: 280px;
  height: 100%;
  min-height: auto;
  max-height: 410px;
  overflow-y: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  h1 {
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      margin-left: 25px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    flex-grow: 1;
    li {
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      gap: 10px;
      .name {
        width: 25%;
        font-size: 14px;
      }

      .comment {
        display: flex;
        align-items: center;
        gap: 10px;

        .commentInput {
          width: 150px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #95a5b2;
          border-radius: 2px;
          padding: 10px 8px;
        }

        .imgPreview {
          width: 20px;
          height: 20px;
        }

        .inputIconContainer {
          display: flex;
          position: relative;
          .imgInput {
            position: absolute;
            left: 0px;
            width: 50px;
            opacity: 0;
            cursor: pointer;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .deleteIcon {
        width: 20px;
        height: 20px;
      }
    }
  }

  button {
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    width: 100%;
    height: 35px;
    background: #13409e 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    height: 50px;
    padding: 10px;
    bottom: 0;
    position: sticky;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}

// for mobile
@include mobile() {
  .container {
    width: 100%;
    min-width: auto;
  }
}
