@import "../../mixins";
.container {
  display: none;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  flex-direction: column;
  margin-bottom: 25px;
  border-radius: 10px;

  .header {
    padding: 20px 20px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: var(--primary-color);
      font-weight: bold;
    }
  }

  .openBody {
    height: 600px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 20px 20px;
  }

  .closeBody {
    height: 0px;
    padding: 0px 20px;
  }

  .body {
    transition: all 450ms ease-in-out;
    overflow: hidden;

    // ====== CATEGORIES =======
    .catContainer {
      width: 100%;
      max-height: 250px;
      overflow-y: auto;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 0px 0px;
      margin-bottom: 40px;

      h2 {
        font: normal normal bold 20px/27px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img {
          margin-left: 10px;
        }
      }

      .categories {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;

        .category {
          width: 48%;
          height: 31px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #cfdbec;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 8px;
          cursor: pointer;

          span {
            color: #1a59da;
            margin-left: 5px;
          }
          p {
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
          }
        }
        .active {
          background: #d6fff9 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #08eecd;
          border-radius: 5px;
          p {
            font-weight: bold;
          }
        }
      }
    }

    // ====== PARTS =======
    .partsContainer {
      width: 100%;
      height: auto;
      max-height: 320px;
      overflow-y: auto;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 10px;

      table {
        width: 100%;
        min-width: 100%;
        border-collapse: collapse;
        thead {
          tr {
            th {
              text-align: right;
              padding-bottom: 10px;
              font: normal normal bold 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              &:first-child {
                padding-right: 20px;
                width: 140px;
              }
            }
          }
        }
        tbody {
          tr {
            height: 55px;
            background: #f1f3f3 0% 0% no-repeat padding-box;
            td {
              text-align: right;
              position: relative;
              .name {
                display: flex;
                align-items: center;
                .checkbox {
                  margin-left: 8px;
                }
                p {
                  font-size: 12px;
                  letter-spacing: 0px;
                  color: #000000;
                }
              }

              .desc {
                width: 90%;
                height: 35px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #95a5b2;
                border-radius: 5px;
                padding: 0px 10px;
              }

              img {
                cursor: pointer;
                width: 20px;
              }
              .imgInput {
                position: absolute;
                left: 20px;
                width: 50px;
                opacity: 0;
                cursor: pointer;
              }
              .imgPreview {
                width: 50px;
                height: 50px;
                border-radius: 10px;
              }

              &:first-child {
                padding-right: 20px;
              }
              &:last-child {
                text-align: left;
                padding-left: 12px;
              }
            }
          }
          tr:nth-child(even) {
            background: #eaf0f2 0% 0% no-repeat padding-box;
          }
        }
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    display: flex;
  }
}
