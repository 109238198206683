@import "../../mixins";
.container {
  width: 696px;
  min-width: 550px;
  //   height: 947px;
  max-height: 380px;
  overflow-y: auto;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 20px;

  table {
    width: 100%;
    min-width: 600px;
    border-collapse: collapse;
    thead {
      tr {
        th {
          text-align: right;
          padding-bottom: 10px;
          font: normal normal bold 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          &:first-child {
            padding-right: 50px;
          }
        }
      }
    }
    tbody {
      tr {
        height: 55px;
        background: #f1f3f3 0% 0% no-repeat padding-box;
        td {
          text-align: right;
          position: relative;
          .name {
            display: flex;
            align-items: center;
            .checkbox {
              margin-left: 10px;
            }
            p {
              font: normal normal normal 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
            }
          }

          .desc {
            width: 287px;
            height: 40px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #95a5b2;
            border-radius: 5px;
            padding: 0px 10px;
          }

          img {
            cursor: pointer;
          }
          .imgInput {
            position: absolute;
            left: 20px;
            width: 50px;
            border: 1px solid #000;
            opacity: 0;
            cursor: pointer;
          }
          .imgPreview {
            width: 50px;
            height: 50px;
            border-radius: 10px;
          }

          &:first-child {
            padding-right: 20px;
          }
          &:last-child {
            text-align: left;
            padding-left: 20px;
          }
        }
      }
      tr:nth-child(even) {
        background: #eaf0f2 0% 0% no-repeat padding-box;
      }
    }
  }
}

// for tablet
@include tablet() {
  .container {
    width: 100%;
    min-width: auto;
  }
}

// for mobile
@include mobile() {
  .container {
    display: none;
  }
}
