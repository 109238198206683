@import "../mixins";
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    padding: 70px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .mainImg {
      width: 100%;
      max-width: 769px;
      height: 510px;
    }
  }
}

// for laptop
@include laptop() {
  .container {
    .wrapper {
      flex-direction: column;
      justify-content: center;
      padding: 50px 10px;
      .mainImg {
        height: auto;
        order: 2;
        margin-top: 30px;
      }
    }
  }
}
