.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 10px 60px 10px;
  .wrapper {
    width: 100%;
    max-width: 973px;
  }
}
