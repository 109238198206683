@import "../mixins";

.container {
  width: 100%;

  .wrapper {
    padding: 60px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .card {
      width: 100%;
      max-width: 1346px;
      min-height: 721px;
      height: auto;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 20px 30px 20px;

      h2 {
        font: normal normal bold 35px/40px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #0d1934;
        margin-bottom: 35px;
      }

      .btn1 {
        width: 225px;
        height: 45px;
        background: #ffca11 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 30px;

        div {
          width: 216px;
          height: 36px;
          border: 2px solid #000000;
          border-radius: 5px;
          font: normal normal bold 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .search {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 45px;

        .field {
          display: flex;
          flex-direction: column;

          label {
            font: normal normal bold 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 10px;
          }

          select {
            width: 230px;
            height: 45px;
            border: 1px solid #95a5b2;
            border-radius: 5px;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
            padding: 0px 5px;
          }

          .datePicker {
            display: flex;
            align-items: center;
            width: 230px;
            height: 45px;
            border: 1px solid #95a5b2;
            border-radius: 5px;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
            padding: 0px 5px;

            input {
              padding: 0px 10px;
              border: none;
              font: normal normal normal 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              width: 100%;
            }
          }
        }
      }

      .tableWrapper {
        width: 100%;

        .mainTable {
          width: 100%;
          min-width: 1200px;
          border-collapse: separate;
          border-spacing: 0 10px;
          margin-bottom: 10px;

          thead {
            tr {
              th {
                text-align: right;
                font: normal normal bold 15px/20px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #000000;
              }
            }
          }

          tbody {
            .noRecords {
              padding: 30px 0px;
              height: 500px;

              td {
                height: 100%;
                h2 {
                  font-size: 28px;
                  margin-bottom: 0px;
                  text-align: center;
                }
              }
            }
            tr {
              background: #f6f6f6 0% 0% no-repeat padding-box;
              height: 46px;

              td {
                text-align: right;
                font: normal normal normal 15px/20px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;

                .part {
                  display: flex;
                  align-items: center;

                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 10px;
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }

                &:first-child {
                  padding: 0px 10px 0px 0px;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                  text-align: center;
                }

                &:nth-child(10) {
                  padding: 0px 0px 0px 20px;
                  text-align: left;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  button {
                    width: 90px;
                    height: 25px;
                    background: #3762ec 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    font: normal normal normal 13px/17px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #ffffff;
                    cursor: pointer;
                  }
                }
                &:nth-child(11) {
                  padding: 0px 0px 0px 20px;
                  text-align: left;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  button {
                    width: 90px;
                    height: 25px;
                    background: #3762ec 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    font: normal normal normal 13px/17px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #ffffff;
                    cursor: pointer;
                  }
                }

                &:nth-child(9) {
                  padding: 0px 0px 0px 20px;
                  text-align: left;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;

                  div {
                    display: flex;
                    // align-items: center;
                    justify-content: flex-end;
                    font: normal normal bold 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: var(--secondary-color);
                    cursor: pointer;

                    img {
                      margin-left: 5px;
                    }
                  }
                }
              }

              .license {
                cursor: pointer;
              }

              &:nth-child(even) {
                background: #eaeef0 0% 0% no-repeat padding-box;
              }
            }

            // ACCORDION
            .collapsedRow {
              // height: 100px;
              width: 100%;
              transform: translateY(-10px);

              td {
                background: #f6f6f6 0% 0% no-repeat padding-box;

                .collapsedTable {
                  width: 100%;
                  border-collapse: collapse;

                  thead {
                    th {
                      text-align: right;
                      padding: 12px 0px;
                      padding: 5px;
                    }
                  }

                  tbody {
                    tr {
                      td {
                        padding: 15px 0px;
                        text-align: right;
                        border-bottom: 1px solid #acacac;
                        padding: 5px;
                        &:first-child {
                          padding: 0px 10px 0px 0px;
                          // text-align: center;
                        }

                        &:last-child {
                          .contact {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            font: normal normal normal 15px/20px "Open Sans",
                              sans-serif;
                            letter-spacing: 0px;
                            color: #000000;

                            img {
                              width: 15px;
                              margin-left: 10px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .btnsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;

        button {
          width: 132px;
          height: 35px;
          background: #13409e 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    .cardsWrapper {
      display: none;
      width: 100%;
      margin-top: 20px;

      @media screen and (max-width: 768px) {
        display: block;
      }

      .cards {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 15px;

        .reqCard {
          background: #FFFFFF;
          border: 1px solid #E0E0E0;
          border-radius: 8px;
          padding: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
          position: relative;

          .cardActions {
            position: absolute;
            top: 8px;
            right: 8px;
            left: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 2;

            input[type="checkbox"] {
              width: 18px;
              height: 18px;
              cursor: pointer;
              border: 2px solid #3762ec;
              border-radius: 4px;
            }

            .archiveBtn {
              display: flex;
              align-items: center;
              gap: 4px;
              background: #f5f5f5;
              color: #666;
              border: 1px solid #ddd;
              padding: 4px 8px;
              border-radius: 4px;
              font-size: 12px;
              cursor: pointer;
              transition: all 0.2s;
              white-space: nowrap;

              svg {
                color: #666;
              }

              &:hover {
                background: #fee2e2;
                color: #cc1919;
                border-color: #cc1919;
                svg {
                  color: #cc1919;
                }
              }
            }
          }

          .cardContent {
            margin-top: 30px;

            .mainInfo {
              .partInfo {
                display: flex;
                gap: 10px;
                margin-bottom: 16px;
                background: #f8f9fa;
                padding: 12px;
                border-radius: 8px;
                border: 1px solid #eee;

                .imageGallery {
                  display: flex;
                  gap: 4px;
                  img {
                    width: 45px;
                    height: 45px;
                    border-radius: 4px;
                    object-fit: cover;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                  }
                }

                .details {
                  flex: 1;
                  .row {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-bottom: 8px;
                    padding: 6px 8px;
                    background: white;
                    border-radius: 6px;
                    border: 1px solid #eee;

                    &:first-child {
                      background: #3762ec0f;
                      border-color: #3762ec30;
                      
                      .value {
                        color: #3762ec;
                        font-size: 15px;
                        font-weight: 600;
                      }
                      
                      svg {
                        color: #3762ec;
                      }
                    }

                    &:nth-child(2) {
                      background: #4caf500f;
                      border-color: #4caf5030;
                      
                      .value {
                        color: #2e7d32;
                        font-size: 14px;
                        font-weight: 600;
                      }
                      
                      svg {
                        color: #2e7d32;
                      }
                    }

                    svg {
                      color: #888;
                      flex-shrink: 0;
                    }

                    .label {
                      color: #666;
                      font-size: 12px;
                      min-width: 40px;
                      font-weight: 500;
                    }

                    .value {
                      color: #333;
                      font-size: 13px;
                      font-weight: 500;
                    }
                  }
                }
              }

              .metaInfo {
                display: flex;
                gap: 8px;
                margin-bottom: 12px;
                flex-wrap: wrap;

                .row {
                  display: flex;
                  align-items: center;
                  gap: 6px;
                  color: #666;
                  font-size: 12px;
                  padding: 6px 10px;
                  background: #f8f9fa;
                  border-radius: 6px;
                  border: 1px solid #eee;
                  flex: 1;
                  min-width: 120px;

                  svg {
                    color: #888;
                    flex-shrink: 0;
                  }

                  span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  &:first-child {
                    background: #f3f4f6;
                    border-color: #e5e7eb;
                    color: #374151;
                    font-weight: 500;
                    flex: 2;
                    
                    svg {
                      color: #374151;
                    }
                  }
                }
              }
            }

            .cardFooter {
              border-top: 1px solid #F0F0F0;
              padding-top: 10px;

              .supplierInfo {
                .infoRow {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 8px;
                  flex-wrap: wrap;
                  gap: 8px;

                  .supplierCount {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    background-color: #fff9c4;
                    padding: 4px 8px;
                    border-radius: 6px;
                    font-size: 12px;
                    color: #333;
                    svg {
                      color: #666;
                    }
                  }

                  .bestPrice {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    background-color: #4CAF50;
                    color: white;
                    padding: 4px 8px;
                    border-radius: 6px;
                    font-size: 12px;
                    font-weight: 500;
                    white-space: nowrap;
                    svg {
                      color: white;
                    }
                  }
                }

                .showResultsBtn {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 6px;
                  background: #3762ec;
                  color: white;
                  border: none;
                  padding: 6px;
                  border-radius: 6px;
                  font-size: 12px;
                  font-weight: 500;
                  cursor: pointer;
                  transition: all 0.2s;

                  &:hover {
                    background: #2951d1;
                  }

                  svg {
                    transition: transform 0.2s;
                  }

                  &:hover svg {
                    transform: translateY(2px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .btnsContainerMob {
      display: none;
    }
  }

  .popupContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;

    .popup {
      width: 100%;
      height: 100%;
      max-width: 538px;
      max-height: 538px;
      margin: 0px 20px;
      position: relative;
      border-radius: 10px;

      span {
        position: absolute;
        right: -20px;
        top: -40px;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
      }
    }
  }
}

// for tablet
@include tablet() {
  .container {
    .wrapper {
      .card {
        .search {
          flex-direction: column;
          width: 100%;

          .field {
            width: 100%;

            select {
              width: 100%;
            }

            .datePicker {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    .wrapper {
      flex-direction: column;

      .card {
        min-height: auto;
        margin-bottom: 25px;
        padding: 30px 20px 0px 20px;

        h2 {
          font-size: 26px;
        }

        .tableWrapper {
          display: none;
        }

        .btnsContainer {
          display: none;
        }
      }

      .btnsContainerMob {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 20px;

        button {
          width: 47%;
          height: 35px;
          font-size: 14px;
          background: #13409e 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          letter-spacing: 0px;
          color: #ffffff;
          cursor: pointer;
        }
      }

      .cardsWrapper {
        display: block;

        .cards {
          display: flex;
          flex-direction: column;
          gap: 0px;
          width: 100%;
          margin-bottom: 5px;
          padding: 0;

          .reqCard {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 25px;
            position: relative;

            .right {
              display: flex;
              flex-direction: column;
              gap: 4px;
              .item {
                // width: 30%;
                display: flex;
                align-items: center;
                // flex-direction: column;
                gap: 5px;
                // border: 1px solid #000;
                margin-left: 6px;

                h3 {
                  font-size: 15px;
                }

                p {
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                  }
                }
              }

              .lastItem {
                width: 100%;
              }

              .results {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                  color: #1a59da;
                  font-weight: bold;
                }
              }
            }

            .left {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
              input {
                position: absolute;
                top: 25px;
                left: 10%;
              }
              .btn {
                width: 120px;
                height: 30px;
                background: #3762ec 0% 0% no-repeat padding-box;
                border-radius: 5px;
                border: none;
                font: normal normal normal 14px/19px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }

          .collapsed {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            margin-bottom: 0px;
          }

          .collapsedCards {
            .collapsedCard {
              background-color: rgba(216, 249, 255, 0.123);
              padding: 10px 10px;
              // height: 120px;
              display: flex;
              flex-direction: column;
              // align-content: end;
              // flex-wrap: wrap;
              gap: 4px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.112);

              &:last-child {
                border-radius: 0px 0px 10px 10px;
                margin-bottom: 25px;
                border-bottom: none;
              }

              .item {
                // width: 30%;
                display: flex;
                align-items: baseline;
                // flex-direction: column;
                gap: 5px;
                margin-left: 6px;

                h3 {
                  font-size: 14px;
                }

                p {
                  font-size: 12px;
                  // max-width: 75px;

                  a {
                    color: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
