@import "../../mixins";
.container {
  width: 100%;
  max-width: 1041px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px 70px;
  margin-bottom: 25px;

  h2 {
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      margin-left: 10px;
    }
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .category {
      width: 165px;
      height: 31px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #cfdbec;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;

      span {
        color: #1a59da;
        margin-left: 5px;
      }
      p {
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
      }
    }
    .active {
      background: #d6fff9 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #08eecd;
      border-radius: 5px;
      p {
        font-weight: bold;
      }
    }
  }
}

// for tablet
@include tablet() {
  .container {
    padding: 20px 20px;
  }
}

// for mobile
@include mobile() {
  .container {
    padding: 20px 20px;
    display: none;

    .categories {
      gap: 10px;
      justify-content: space-between;

      .category {
        width: 48%;
        height: 31px;
      }
    }
  }
}
