@import "../../mixins";
.container {
  width: 100%;
  max-width: 416px;
  height: 174px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 25px #12325529;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  padding: 20px 20px;
  position: relative;
  img {
    margin-left: 30px;
  }

  .text {
    padding: 35px 0px;
    h6 {
      font: normal normal bold 20px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: var(--secondary-color);
      margin-bottom: 13px;
    }
    p {
      font: normal normal normal 17px/25px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #0d1934;
      width: 100%;
      max-width: 255px;
    }
  }

  .vector {
    position: absolute;
  }
}

.one {
  align-self: flex-start;

  .vector {
    left: -202px;
    bottom: 30px;
  }
}
.two {
  align-self: flex-end;
  transform: translateY(-30px);

  .vector {
    right: -180px;
    bottom: 0px;
  }
}
.three {
  align-self: flex-start;
  transform: translateY(-20px);

  .vector {
    left: -202px;
    bottom: 15px;
  }
}
.four {
  align-self: flex-end;
  transform: translateY(-30px);
}

// for tablets
@include tablet() {
  .container {
    margin-bottom: 20px;
    .vector {
      display: none;
    }
  }
  .one {
    align-self: flex-start;
  }
  .two {
    align-self: flex-end;
    transform: unset;
  }
  .three {
    align-self: flex-start;
    transform: unset;
  }
  .four {
    align-self: flex-end;
    transform: unset;
  }
}

// for mobile
@include mobile() {
  .container {
    max-width: 100%;
    flex-direction: column;
    height: auto;

    .text {
      padding: 25px 0px;
    }
  }
}
