@import "../mixins";


.myForm {
  // Your common styles here
  display: grid;
  padding: 1rem;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    // Your responsive styles for smaller screens here
  grid-template-columns: 1fr;

  }
}
.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999;
  .container {
    width: 100%;
    height: auto;

    max-width: 600px;
    // max-height: 538px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;

    overflow-y: auto;
    padding: 20px 20px 20px 20px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    .close {
      position: absolute;
      right: 20px;
      top: 10px;
      font: normal normal normal 32px/30px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      h4 {
        text-align: center;
        font-size: 26px;
        margin-bottom: 25px;
      }

      .block {
        margin-bottom: 15px;
        label {
          font-weight: 500;
          color: #000000c6;
        }
        p {
          font-size: 18px;
        }
      }
    }
  }
}

// for tablet
@include tablet() {
  .mainContainer {
    .container {
      max-height: 568px;
      .content {
        height: auto;
        width: auto;
      }
    }
  }
}

// for mobile
@include mobile() {
  .mainContainer {
    .container {
      padding: 20px 10px;
      .close {
        right: 10px;
        top: 10px;
        font-size: 25px;
      }
      .content {
        padding: 20px 0px;
      }
    }
  }
}
