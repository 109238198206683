@import "../../mixins";
.container {
  width: 100%;
  padding: 50px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  .wrapper {
    .card {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        max-width: 544px;
        height: 202px;
        margin-bottom: 10px;
      }

      h3 {
        font: normal normal bold 20px/25px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: var(--secondary-color);
        margin-bottom: 20px;
      }

      p {
        width: 100%;
        max-width: 752px;
        text-align: center;
        font: normal normal normal 20px/25px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        position: relative;
        margin-bottom: 20px;
        img {
          position: absolute;
          width: 43px;
          height: 34px;
        }
        .right {
          right: -40px;
          top: -30px;
        }
        .left {
          left: -40px;
          bottom: -30px;
          z-index: 9999;
        }
      }
    }
  }
}

// for tablets
@include tablet() {
  .container {
    .wrapper {
      .card {
        padding: 10px;
        img {
          margin-bottom: 30px;
          height: auto;
        }
        p {
          display: flex;
          padding: 0px 15px;
          font-size: 18px;
          img {
            position: relative;
            left: unset !important;
            right: unset !important;
            width: 33px;
            height: 28px;
          }
          .left {
            margin-top: 20px;
            align-self: flex-end;
          }
          .right {
            align-self: flex-start;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    .wrapper {
      .card {
        padding: 10px;
        img {
          margin-bottom: 30px;
        }
        p {
          display: flex;
          padding: 0px 15px;
          font-size: 16px;
          img {
            position: relative;
            left: unset !important;
            right: unset !important;
            width: 33px;
            height: 28px;
          }
          .left {
            margin-top: 20px;
            align-self: flex-end;
          }
          .right {
            align-self: flex-start;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
