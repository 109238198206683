@import "../mixins";

.container {
    width: 100%;
    min-height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper {
        padding: 70px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .card {
            width: 100%;
            max-width: 644px;
            height: auto;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 50px 20px;

            h2 {
                font: normal normal bold 25px/34px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: var(--secondary-color);
                margin-bottom: 15px;
            }

            .text {
                font: normal normal normal 15px/20px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #000000;
                margin-bottom: 20px;
                text-align: center;
            }

            .field {
                display: flex;
                flex-direction: column;
                margin-bottom: 25px;
                width: 100%;
                max-width: 300px;

                label {
                    font: normal normal bold 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    margin-bottom: 10px;
                }

                input {
                    width: 100%;
                    height: 45px;
                    border: 1px solid #95a5b2;
                    border-radius: 5px;
                    font: normal normal normal 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    padding: 0px 10px;
                }

                .error {
                    color: red;
                }
            }

            button {
                align-self: center;
                width: 100%;
                max-width: 300px;
                height: 45px;
                background: var(--primary-color) 0% 0% no-repeat padding-box;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                font: normal normal bold 20px/27px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #ffffff;
            }
        }
    }
}

// for mobile
@include mobile() {
    .container {
        .wrapper {
            padding: 50px 10px;

            .card {
                padding: 20px 10px;

                .text {
                    width: 100%;
                    max-width: 400px;
                }

                .field {
                    flex-wrap: wrap;
                    margin-bottom: 25px;
                    max-width: 100%;

                    .radioRow {
                        flex-wrap: wrap;
                    }

                }

                button {
                    max-width: 100%;
                    font-size: 18px;
                }
            }
        }
    }
}