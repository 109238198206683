@import "../../mixins";
.container {
  width: 100%;
  max-width: 644px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  h3 {
    font: normal normal bold 25px/34px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: var(--secondary-color);
    margin-bottom: 20px;
  }

  p {
    width: 100%;
    max-width: 446px;
    height: 40px;
    text-align: center;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #060202;
    margin-bottom: 25px;
  }

  form {
    width: 100%;
    max-width: 225px;
    .fieldWrapper {
      margin-bottom: 12px;
      .field {
        width: 100%;
        height: 45px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0px 5px;

        input {
          border: none;
          padding: 0px 5px;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          width: 100%;
        }
      }
      .error {
        color: red;
      }
    }
    button {
      width: 100%;
      height: 45px;
      background: var(--primary-color) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      font: normal normal bold 20px/27px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .reset {
      text-decoration: underline;
      color: var(--primary-color);
      cursor: pointer;
    }
  }
  .logo {
    width: 196px;
    height: 31px;
    position: absolute;
    bottom: 10px;
    left: 20px;
  }
}

// for tablet
@include tablet() {
  .container {
    padding: 0px 10px;
  }
}
// for mobile
@include mobile() {
  .container {
    padding: 10px 10px;
    height: auto;
    padding-bottom: 20px;
    p {
      margin-bottom: 35px;
    }
    form {
      max-width: 100%;
      .field {
        width: 100%;
      }
      button {
        font-size: 18px;
      }
    }
    .logo {
      left: 10px;
    }
  }
}
