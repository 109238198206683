@import "../mixins";
.container {
  min-width: 300px;
  height: 329px;
  background: #eef3fb 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 25px #cfdbec;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  padding: 15px 0;
  .plan {
    // position: absolute;
    bottom: 235px;
    font: normal normal bold 30px/41px "Open Sans", sans-serif;
    letter-spacing: 0px;
    text-align: center;
    padding: 0px 15px;
  }

  .text {
    // position: absolute;
    bottom: 210px;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
  }

  .price {
    // position: absolute;
    bottom: 120px;
    font: normal normal bold 63px/65px "Open Sans", sans-serif;
    letter-spacing: 0px;
    span {
      font-size: 30px;
    }
  }
  .duration {
    // position: absolute;
    bottom: 100px;
    font: normal normal normal 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
  }
  .btn {
    // position: absolute;
    bottom: 12px;
    width: 100%;
    max-width: 225px;
    height: 45px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: var(--secondary-color);
    border: none;
    cursor: pointer;
  }
}

.primary {
  .plan {
    color: #ffffff;
  }
  .text {
    color: #ffffff;
  }
  .price {
    color: #ffffff;
  }
  .duration {
    color: #ffffff;
  }
}

.secondary {
  .plan {
    color: var(--secondary-color);
  }
  .text {
    color: var(--secondary-color);
  }
  .price {
    color: var(--secondary-color);
  }
  .duration {
    color: var(--secondary-color);
  }
  .btn {
    border: 1px solid #1a59da;
  }
}

// for mobile
@include mobile() {
    .container {
      width: 100%;
      max-width: 300px;
  }
}
