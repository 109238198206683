@import "../mixins";
.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #eff1f5 0% 0% no-repeat padding-box;

  // TOP
  .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 288px;
    background: #ffffff 0% 0% no-repeat padding-box;

    // LEFT
    .left {
      img {
        width: 432px;
        height: 288px;
      }
    }

    // MIDDLE
    .middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo {
        margin-bottom: 10px;
        width: 218px;
        height: 46px;
      }
      nav {
        margin-bottom: 25px;
        ul {
          display: flex;
          align-items: center;
          gap: 50px;
          li {
            list-style: none;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer;
            &:hover {
              color: var(--primary-color);
            }
          }
          .active {
            font: normal normal bold 15px/20px "Open Sans", sans-serif;
            color: var(--primary-color);
          }
        }
      }
      .btns {
        display: flex;
        align-items: center;
        gap: 15px;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 161px;
          height: 35px;
          border-radius: 5px;
          border: none;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          cursor: pointer;
          img {
            margin-bottom: 0px;
          }

          &:nth-child(1) {
            color: #ffffff;
            background: var(--secondary-color) 0% 0% no-repeat padding-box;
          }
          &:nth-child(2) {
            border: 1px solid var(--secondary-color);
            background-color: transparent;
            color: var(--secondary-color);
          }
        }
      }
    }

    // RIGHT
    .right {
      img {
        width: 386px;
        height: 257px;
      }
    }
  }

  // BOTTOM
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;

    p {
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 5px;
    }
  }
}

// for laptops
@include laptop() {
  .footer {
    // TOP
    .top {
      height: 288px;

      // LEFT
      .left {
        align-self: flex-start;
        img {
          width: 375px;
          height: 260px;
        }
      }

      // MIDDLE
      .middle {
        nav {
          ul {
            gap: 30px;
          }
        }
        .btns {
          gap: 10px;
        }
      }

      // RIGHT
      .right {
        align-self: flex-end;
        img {
          width: 350px;
          height: 243px;
        }
      }
    }
  }
}

// for tablets
@include tablet() {
  .footer {
    // TOP
    .top {
      height: auto;
      flex-direction: column;
      // LEFT
      .left {
        align-self: flex-end;
        order: 1;
      }

      // MIDDLE
      .middle {
        order: 2;
        nav {
          ul {
            gap: 30px;
          }
        }
        .btns {
          gap: 10px;
        }
      }

      // RIGHT
      .right {
        order: 3;
        align-self: flex-start;
      }
    }
  }
}

// for mobile
@include mobile() {
  .footer {
    // TOP
    .top {
      // LEFT
      .left {
        img {
          height: 100%;
          width: 100%;
        }
      }

      // MIDDLE
      .middle {
        nav {
          ul {
            gap: 15px;
            li {
              font-size: 14px;
            }
          }
        }
        .btns {
          flex-direction: column;
        }
      }

      // RIGHT
      .right {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
