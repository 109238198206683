@import "../mixins";
.container {
  width: 100%;
  position: relative;
  .wrapper {
    padding: 60px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    h2 {
      font: normal normal bold 35px/40px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #0d1934;
      margin-bottom: 35px;
    }
    .card {
      width: 100%;
      max-width: 1346px;
      // min-height: 721px;
      height: auto;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 20px 30px 20px;

      .search {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
        input {
          width: 100%;
          max-width: 647px;
          height: 45px;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          margin-left: 10px;
          padding: 0px 10px;
        }
        button {
          width: 72px;
          height: 45px;
          background: #1c58da 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }

      .mainTable {
        width: 100%;
        min-width: 1200px;
        border-collapse: separate;
        border-spacing: 0 10px;
        margin-bottom: 10px;
        thead {
          tr {
            th {
              text-align: right;
              font: normal normal bold 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
            }
          }
        }
        tbody {
          tr {
            background: #f6f6f6 0% 0% no-repeat padding-box;
            height: 46px;
            td {
              text-align: right;
              font: normal normal normal 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              padding: 5px 5px;
              .part {
                display: flex;
                align-items: center;
                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 10px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }

              &:first-child {
                padding: 0px 10px 0px 0px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                text-align: center;
              }

              &:last-child {
                padding: 0px 0px 0px 20px;
                text-align: left;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                // div {
                //   display: flex;
                //   align-items: center;
                //   justify-content: flex-end;
                //   font: normal normal bold 15px/20px "Open Sans", sans-serif;
                //   letter-spacing: 0px;
                //   color: var(--secondary-color);
                //   cursor: pointer;
                //   img {
                //     margin-left: 5px;
                //   }
                // }
                button {
                  width: 120px;
                  height: 30px;
                  background: #3762ec 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                  border: none;
                  font: normal normal normal 14px/19px "Open Sans", sans-serif;
                  letter-spacing: 0px;
                  color: #ffffff;
                  cursor: pointer;
                }
              }
            }

            .license {
              cursor: pointer;
            }

            &:nth-child(even) {
              background: #eaeef0 0% 0% no-repeat padding-box;
            }
          }

          // ACCORDION
          .collapsedRow {
            // height: 100px;
            width: 100%;
            transform: translateY(-10px);
            td {
              border-radius: 0px;
              background: #f6f6f6 0% 0% no-repeat padding-box;

              .collapsedTable {
                width: 100%;
                border-collapse: collapse;

                thead {
                  th {
                    text-align: right;
                    padding: 12px 0px;
                    padding: 5px;
                  }
                }
              }

              .already {
                display: flex;
                justify-content: flex-start;
                p {
                  color: #3762ec;
                  font-weight: bold;
                }
              }

              .boxes {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 20px 0px;
                .havePart {
                  width: 185px;
                  height: auto;
                  padding: 10px 0px;
                  background: #e4ebff 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin-left: 20px;

                  input {
                    width: 100%;
                    max-width: 153px;
                    height: 35px;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #b1b1b1;
                    border-radius: 5px;
                    font: normal normal normal 13px/18px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #6e6e6e;
                    padding: 0px 10px;
                    margin-bottom: 10px;
                  }

                  h6 {
                    font: normal normal bold 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #122438;
                    margin-bottom: 10px;
                  }
                  p {
                    width: 100%;
                    max-width: 150px;
                    text-align: center;
                    font: normal normal normal 13px/18px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    margin-bottom: 15px;
                  }
                  button {
                    // width: 132px;
                    width: 100%;
                    max-width: 153px;
                    height: 35px;
                    background: #3762ec 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    font: normal normal normal 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #ffffff;
                    cursor: pointer;
                  }
                }

                .offer {
                  width: 185px;
                  height: auto;
                  background: #e4ebff 0% 0% no-repeat padding-box;
                  border-radius: 5px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  margin-left: 20px;
                  padding: 10px 0px;

                  h6 {
                    font: normal normal bold 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #122438;
                    margin-bottom: 10px;
                  }
                  input {
                    width: 100%;
                    max-width: 153px;
                    height: 35px;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #b1b1b1;
                    border-radius: 5px;
                    font: normal normal normal 13px/18px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #6e6e6e;
                    padding: 0px 10px;
                    margin-bottom: 12px;
                  }
                  button {
                    width: 153px;
                    height: 35px;
                    background: #19ad79 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    font: normal normal normal 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #ffffff;
                    cursor: pointer;
                  }
                }
                .reject {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  button {
                    width: 153px;
                    height: 35px;
                    background: #ffb2b2 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    font: normal normal normal 15px/20px "Open Sans", sans-serif;
                    letter-spacing: 0px;
                    color: #000000;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
    .cardsWrapper {
      display: none;
    }
    .pagination {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 15px;
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-wrap: wrap;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          list-style: none;
          width: 27px;
          height: 27px;
          background-color: #ffffff;
          border-radius: 3px;
          box-shadow: 0px 1px 3px #00000029;
          cursor: pointer;
        }
        .active {
          background-color: #3762ec;
          color: #ffffff;
        }
      }
      button {
        background-color: #3762ec;
        color: #ffffff;
        border: none;
        width: 40px;
        height: 27px;
        border-radius: 3px;
        box-shadow: 0px 1px 3px #00000029;
        cursor: pointer;
      }
    }
  }

  .popupContainer {
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    .popup {
      width: 100%;
      height: 100%;
      max-width: 538px;
      max-height: 538px;
      margin: 0px 20px;
      position: relative;
      border-radius: 10px;
      span {
        position: absolute;
        right: -20px;
        top: -40px;
        font-size: 40px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}

// for mobile
@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      flex-direction: column;
      h2 {
        font-size: 26px;
      }

      .card {
        display: none;
      }
      .cardsWrapper {
        display: block;

        .cards {
          display: flex;
          flex-direction: column;
          gap: 0px;
          width: 100%;
          margin-bottom: 25px;

          .reqCard {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            margin-bottom: 25px;

            .item {
              width: 30%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              h3 {
                font-size: 15px;
              }

              p {
                font-size: 14px;
              }

              img {
                width: 20px;
              }

              &:last-child {
                margin-top: 10px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .lastItem {
              width: 100%;
            }

            .btn {
              width: 120px;
              height: 30px;
              background: #3762ec 0% 0% no-repeat padding-box;
              border-radius: 5px;
              border: none;
              font: normal normal normal 14px/19px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #ffffff;
              cursor: pointer;
            }

            .results {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;

              p {
                color: #1a59da;
                font-weight: bold;
              }
            }
          }

          .collapsed {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            margin-bottom: 0px;
          }

          .collapsedCards {
            .collapsedCard {
              background-color: rgba(128, 128, 128, 0.121);
              padding: 20px;
              display: flex;
              flex-wrap: wrap;
              gap: 15px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.112);

              &:last-child {
                border-radius: 0px 0px 10px 10px;
                margin-bottom: 25px;
                border-bottom: none;
              }

              .item {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                h3 {
                  font-size: 15px;
                }

                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
