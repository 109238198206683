@import "../mixins";
.container {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  .wrapper {
    padding: 60px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
      font: normal normal medium 35px/52px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #212121;
      margin-bottom: 17px;
    }
    p {
      font: normal normal 300 20px/29px "Open Sans", sans-serif;
      letter-spacing: -0.5px;
      color: #0d2335;
      margin-bottom: 12px;
    }
    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 524px;
      margin-bottom: 35px;
      input,
      textarea {
        width: 100%;
        background: #f4fafb 0% 0% no-repeat padding-box;
        border: 1px solid #b1d1d6;
        border-radius: 5px;
        font: normal normal normal 15px/30px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #285055;
        padding: 0px 12px;
        margin-bottom: 15px;
      }
      input {
        height: 45px;
        max-width: 100% !important;
      }
      textarea {
        height: 117px;
        resize: none;
      }
      button {
        width: 100%;
        height: 45px;
        background: var(--secondary-color) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        font: normal normal bold 15px/30px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
    }

    .cards {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 12px;
      width: 100%;
    }
  }
}

// for mobile
@include mobile() {
  .container {
    .wrapper {
      h2 {
        margin-bottom: 15px;
      }
      p {
        font-size: 16px;
        text-align: center;
      }
    }
  }
}
