@import "../../mixins";
.container {
  width: 324px;
  height: 219px;
  background: #e8ecf0 0% 0% no-repeat padding-box;
  border: 1px solid #1a59da;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    position: absolute;
    margin-bottom: 10px;
    top: 50px;
  }

  h3 {
    position: absolute;
    top: 112px;
    font: normal normal bold 20px/27px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
  }
  p {
    position: absolute;
    top: 142px;
    font: normal normal normal 17px/23px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

// for mobile
@include mobile() {
  .container {
    width: 100%;
  }
}
