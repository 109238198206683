@import "../mixins";
.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999;
  .container {
    width: 100%;
    height: auto;

    max-width: 600px;
    max-height: 565px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;

    overflow-y: auto;
    overflow-x: hidden;
    padding: 60px 20px 20px 20px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    .close {
      position: absolute;
      right: 20px;
      top: 10px;
      font: normal normal normal 32px/30px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      p {
        max-width: 350px;
        margin-bottom: 12px;
      }

      .licenseContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        position: relative;
        gap: 10px;

        .licenseRight {
          display: flex;
          align-items: center;
          gap: 10px;
          .licenseNum {
            width: 225px;
            height: 45px;
            background: #ffca11 0% 0% no-repeat padding-box;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .wrapper {
              width: 216px;
              height: 36px;
              border: 2px solid #000000;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .left {
                input {
                  height: 33px;
                  width: 100%;
                  padding: 0px 10px;
                  font: normal normal bold 15px/20px "Open Sans", sans-serif;
                  letter-spacing: 0px;
                  color: #000000;
                  background-color: transparent;
                  border: none;
                  text-align: center;
                  &::placeholder {
                    text-align: center;
                  }
                }
              }

              .right {
                width: 21px;
                height: 33px;
                background: #165492 0% 0% no-repeat padding-box;
                border-radius: 2px 0px 0px 2px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font: normal normal normal 15px/20px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #ffffff;
              }
            }
          }

          .licenseBtn {
            width: fit-content !important;
            padding: 0px 20px;
          }
        }

        button {
          width: 184px;
          height: 40px;
          background: #486db9 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
          margin: 0px;

          &:disabled {
            cursor: not-allowed;
          }

          &:hover:enabled ~ .dropCard {
            display: flex;
          }
        }

        .dropCard {
          position: absolute;
          left: 0px;
          top: 42px;
          width: 500px;
          height: auto;
          z-index: 999999;

          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 10px;
          display: none;
          flex-direction: column;
          padding: 20px;

          ul {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            gap: 1px;

            li {
              width: 47%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              p {
                font-size: 12px;

                &:nth-child(1) {
                  font-weight: 600;
                }
                &:nth-child(2) {
                  color: #555;
                }
              }
            }
          }
        }
      }
      .details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        input {
          width: 48%;
          height: 40px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #95a5b2;
          border-radius: 5px;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          margin-bottom: 18px;
          padding-right: 15px;
          padding-left: 15px;
          resize: none;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;

          &:disabled {
            cursor: not-allowed;
          }
          &::placeholder {
            color: #000000;
          }
        }
      }

      textarea {
        width: 100%;
        height: 100px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #95a5b2;
        border-radius: 5px;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 18px;
        padding-right: 15px;
        padding-left: 15px;
        resize: none;
        &:disabled {
          cursor: not-allowed;
        }
        &::placeholder {
          color: #000000;
        }
      }
      label {
        margin-bottom: 10px;
      }
      .upload {
        position: relative;
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
        input {
          position: absolute;
          opacity: 0;
          width: 80px;
          height: 80px;
          cursor: pointer;
        }
        .box {
          width: 100%;
          height: 100%;
          border: 1px dashed #95a5b2;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 26px;
          }
        }
      }

      .preview {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        margin: 10px 0px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 5px;
        }
      }

      button {
        margin-top: 5px;
        width: 100%;
        height: 45px;
        background: var(--primary-color) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal bold 20px/27px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        border: none;
        cursor: pointer;
        span {
          margin: 0px 15px 0px 30px;
        }
      }
    }
  }
}

// for tablet
@include tablet() {
  .mainContainer {
    .container {
      max-height: 568px;
      .content {
        height: auto;
        width: auto;
        .licenseContainer {
          .dropCard {
            left: 0;
            right: 0;
            top: 60px;
            margin: auto;
          }
        }
      }
    }
  }
}

// for mobile
@include mobile() {
  .mainContainer {
    .container {
      padding: 20px 10px;
      .close {
        right: 10px;
        top: 10px;
        font-size: 25px;
      }
      .content {
        padding: 20px 0px;
        .licenseContainer {
          display: flex;
          flex-direction: column;

          .licenseRight {
            margin-bottom: 10px;
            .licenseNum {
              max-width: 180px;
              width: 100%;
            }
          }
          button {
            max-width: 220px;
            width: 100%;
          }
          .dropCard {
            width: 100%;
            max-width: 400px;
            max-height: 350px;
            overflow-y: auto;
            padding: 15px;
            top: 100px;
            ul {
              display: flex;
              flex-direction: column;
              li {
                width: 100%;
                max-width: auto;
                flex-wrap: wrap;
                p {
                  word-wrap: break-word;
                  max-width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}
