@import "../mixins";
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    padding: 70px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .card {
      width: 891px;
      min-height: 672px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;

      h3 {
        font: normal normal bold 25px/34px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: var(--secondary-color);
        margin-bottom: 5px;
      }

      .text1 {
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 20px;
        text-align: center;
      }

      form {
        width: 100%;
        max-width: 750px;
        display: flex;
        flex-direction: column;
        .row {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;
          .field {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            label {
              font: normal normal bold 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              margin-bottom: 10px;
            }
            .radioRow {
              display: flex;
              align-items: center;
              gap: 17px;
              margin-bottom: 10px;
              .radio {
                font: normal normal bold 15px/20px "Open Sans", sans-serif;
                letter-spacing: 0px;
                color: #000000;
                input {
                  margin-left: 5px;
                }
              }
            }
            .error {
              color: red;
            }
          }
        }

        .formFields {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 15px;

          .field {
            width: 100%;
            max-width: 240px;
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            label {
              font: normal normal bold 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              margin-bottom: 10px;
            }

            input[type="text"],
            input[type="password"],
            select {
              width: 100%;
              max-width: 100% !important;
              height: 45px;
              border: 1px solid #95a5b2;
              border-radius: 5px;
              font: normal normal normal 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              padding: 0px 10px;
            }
            .error {
              color: red;
            }
          }
        }

        .text2 {
          text-align: center;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #000000;
          margin-bottom: 15px;
          margin-top: 20px;
        }
        .terms {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          input {
            margin-left: 5px;
          }

          .text3 {
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
            span {
              color: var(--secondary-color);
            }
          }
        }

        button {
          align-self: center;
          width: 100%;
          max-width: 225px;
          height: 45px;
          background: var(--primary-color) 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          cursor: pointer;
          font: normal normal bold 20px/27px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    .wrapper {
      padding: 50px 10px;
      .card {
        height: auto;
        padding: 20px 10px;
        .text1 {
          width: 100%;
          max-width: 400px;
        }
        form {
          .row {
            flex-wrap: wrap;
            .field {
              flex-wrap: wrap;
              margin-bottom: 10px;
              .radioRow {
                flex-wrap: wrap;
              }
            }
          }
          .formFields {
            .field {
              width: 100%;
              flex-wrap: wrap;
              margin-bottom: 10px;
              max-width: 100%;
              .radioRow {
                flex-wrap: wrap;
              }
              input[type="text"],
              select {
                width: 100%;
                max-width: 100%;
              }
            }
          }
          button {
            max-width: 100%;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  input[type="text"],
  input[type="password"],
  select {
    width: 100%;
    height: 45px;
    border: 1px solid #95a5b2;
    border-radius: 5px;
    font: normal normal normal 15px/20px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    padding: 0px 10px;
  }
}
