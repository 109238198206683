@import "../mixins";
.container {
  width: 100%;
  min-height: 80vh;
  .wrapper {
    padding: 60px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .rowWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 19px;
      width: 100%;
      max-width: 1041px;
    }
  }
}

// for tablet
@include tablet() {
  .container {
    .wrapper {
      .rowWrap {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

// for mobile
@include mobile() {
}
