@import "../mixins";
.mainContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  .container {
    width: 100%;
    height: 100%;

    max-width: 500px;
    max-height: 320px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;

    overflow-y: auto;
    padding: 20px 20px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    .close {
      position: absolute;
      right: 20px;
      top: 10px;
      font: normal normal normal 32px/30px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      cursor: pointer;
    }
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .heading1 {
        font-size: 32px;
        letter-spacing: 0px;
        color: var(--secondary-color);
        margin-bottom: 20px;
      }
      .text {
        font-size: 16px;
        line-height: 25px;
        font-weight: 600;
        letter-spacing: 0px;
        color: #000000;
        text-align: center;
        margin-bottom: 20px;
      }
      .btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        button {
          width: 132px;
          height: 35px;
          background: #13409e 0% 0% no-repeat padding-box;
          border-radius: 5px;
          border: none;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .note {
        margin-top: 20px;
        font-size: 14px;
        color: #666565;
      }
    }
  }
}

// for tablet
@include tablet() {
  .mainContainer {
    .container {
      max-height: 568px;
      .content {
        height: auto;
        width: auto;
        .cards {
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
}

// for mobile
@include mobile() {
  .mainContainer {
    .container {
      padding: 20px 10px;
      .close {
        right: 10px;
        top: 10px;
        font-size: 25px;
      }
      .content {
        padding: 20px 0px;
        .heading1,
        .text,
        .heading2 {
          text-align: center;
        }
        .heading1 {
          font-size: 20px;
        }
        .heading2 {
          font-size: 16px;
          margin-bottom: 20px;
        }
        .text {
          font-size: 14px;
        }
      }
    }
  }
}
