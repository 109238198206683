@import "../../mixins";
.outer {
  margin: auto;
  margin-top: 15%;
  background: #ffffff;
  height: 100px;
  width: 100px;
  border-radius: 15px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999999999999999;

  .middle {
    height: 60px;
    width: 60px;
    margin: auto;
    position: relative;
    top: 20px;
    border-radius: 50%;
    background-image: linear-gradient(
        150deg,
        transparent 50%,
        var(--primary-color) 50%
      ),
      linear-gradient(90deg, var(--primary-color) 50%, white 50%);
    -webkit-animation: rotation 1200ms infinite linear;
    animation: rotation 1200ms infinite linear;
    transform-origin: 50% 50%;
    animation-timing-function: ease;

    .inner {
      background: #ffffff;
      height: 48px;
      width: 48px;
      margin: auto;
      position: relative;
      top: 6px;
      border-radius: 50%;
    }
  }
}
@keyframes rotation {
  from {
    transform: rotate(270deg);
  }
  to {
    transform: rotate(630deg);
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }
  to {
    -webkit-transform: rotate(630deg);
  }
}

.container {
  position: relative;
  width: 100%;
  max-width: 488px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 25px #12325529;
  border-radius: 10px;
  z-index: 2;
  padding: 30px 12px;
  z-index: 999999999999;

  h2 {
    font: normal normal bold 25px/30px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #0d1934;
    margin-bottom: 15px;
  }
  p {
    font: normal normal normal 15px/17px "Open Sans", sans-serif;
    width: 100%;
    max-width: 429px;
    letter-spacing: 0px;
    color: #0d1934;
    margin-bottom: 8px;
  }

  h4 {
    font: normal normal bold 20px/25px "Open Sans", sans-serif;
    letter-spacing: 0px;
    color: #0d1934;
    margin-bottom: 20px;
  }
  .licenseContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;

    .licenseRight {
      display: flex;
      align-items: center;
      gap: 10px;
      .licenseNum {
        width: 225px;
        height: 45px;
        background: #ffca11 0% 0% no-repeat padding-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .wrapper {
          width: 225px;
          height: 45px;
          border: 2px solid #000000;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            input {
              height: 33px;
              width: 100%;
              padding: 0px 10px;
              font: normal normal bold 15px/20px "Open Sans", sans-serif;
              letter-spacing: 0px;
              color: #000000;
              background-color: transparent;
              border: none;
              text-align: center;
              &::placeholder {
                text-align: center;
              }
            }
          }

          .right {
            width: 21px;
            height: 33px;
            height: 43px;
            padding: 5px;
            background: #165492 0% 0% no-repeat padding-box;
            border-radius: 2px 0px 0px 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }
      button {
        background-color: var(--primary-color);
      }
    }

    button {
      width: 184px;
      width: auto;
      padding: 0px 15px;
      height: 40px;
      background: #486db9 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      margin: 0px;

      &:disabled {
        cursor: not-allowed;
      }

      // &:hover:enabled ~ .dropCard {
      //   display: flex;
      // }
    }

    .my-select {
      &:focus {
        outline: 2px solid blue; /* Change this to your desired highlight style */
      }
    }
    
    .dropCard {
      position: absolute;
      left: 0px;
      top: 42px;
      width: 500px;
      height: auto;
      z-index: 999999999999;

      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      flex-direction: column;
      padding: 20px;

      span {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
        font-size: 16px;
      }

      ul {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        gap: 1px;

        li {
          width: 47%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            font-size: 12px;

            &:nth-child(1) {
              font-weight: 600;
            }
            &:nth-child(2) {
              color: #555;
            }
          }
        }
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    .tab {
      width: 150px;
      height: 40px;
      background: #d5e1f2 0% 0% no-repeat padding-box;
      border-radius: 23px;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;
      img {
        margin: 0px 15px;
      }
    }
    .active {
      background: #aec5e5 0% 0% no-repeat padding-box;
      border: 2px solid #1a59da;
      border-radius: 23px;
    }

    .disable {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    select,
    input {
      width: 225px;
      height: 45px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #95a5b2;
      border-radius: 5px;
      font: normal normal normal 15px/20px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 18px;
      padding-right: 15px;
      padding-left: 15px;
      &:disabled {
        cursor: not-allowed;
      }
      &::placeholder {
        color: #000000;
      }
    }
    select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      background-image: url("../../../assets/down.svg");
      background-repeat: no-repeat;
      background-position: 5px center;
      background-size: 18px;
    }

    button {
      width: 225px;
      height: 45px;
      background: var(--primary-color) 0% 0% no-repeat padding-box;
      border-radius: 5px;
      font: normal normal bold 20px/27px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #ffffff;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin: 0px 15px 0px 30px;
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
    .demolishText {
      margin-bottom: 10px;
      text-align: center;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

// for tablet
@include tablet() {
  .container {
    .licenseContainer {
      .dropCard {
        left: 0;
        right: 0;
        top: 60px;
        margin: auto;
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    height: auto;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 22px;
      text-align: center;
    }

    p {
      font-size: 14px;
      text-align: center;
    }

    h4 {
      text-align: center;
      font-size: 18px;
    }
    .licenseContainer {
      display: flex;
      flex-direction: column;

      .licenseRight {
        margin-bottom: 10px;
        .licenseNum {
          max-width: 220px;
          width: 100%;
        }
      }
      button {
        max-width: 220px;
        width: 100%;
      }
      .dropCard {
        width: 100%;
        max-width: 400px;
        max-height: 350px;
        overflow-y: auto;
        padding: 15px;
        top: 100px;
        ul {
          display: flex;
          flex-direction: column;
          li {
            width: 100%;
            max-width: auto;
            flex-wrap: wrap;
            p {
              word-wrap: break-word;
              max-width: 100px;
            }
          }
        }
      }
    }

    .tabs {
      margin-bottom: 20px;
      justify-content: center;
      gap: 12px;
      .tab {
        justify-content: unset;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100px;
        height: 35px;
        font: normal normal normal 13px/18px Open Sans, sans-serif;
        img {
          margin: 0px 12px;
          width: 25px;
        }
      }
    }

    .form {
      flex-direction: row;
      flex-wrap: wrap;
      select,
      input {
        width: 48%;
      }
      button {
        width: 100%;
        justify-content: center;
        span {
          display: none;
        }
      }
      .demolishText {
        margin-top: 15px;
        margin-bottom: 0px;
      }
    }
  }
}
