@import "../mixins";
.container {
  width: 100%;
  min-height: 100vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  .wrapper {
    padding: 60px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h2 {
      font: normal normal bold 35px/40px "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #0d1934;
      margin-bottom: 45px;
    }

    .vehicles {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      width: fit-content;
      margin-bottom: 35px;
      .tab {
        width: 150px;
        height: 40px;
        background: #d5e1f2 0% 0% no-repeat padding-box;
        border: 2px solid #d5e1f2;
        border-radius: 23px;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #000000;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin: 0px 15px;
        }
      }
      .active {
        background: #aec5e5 0% 0% no-repeat padding-box;
        border: 2px solid #1a59da;
        border-radius: 23px;
      }

      .disable {
        opacity: 0.7;
        cursor: not-allowed;
      }
    }

    .card {
      .assignAll {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;
        cursor: pointer;
        width: fit-content;

        p {
          font-weight: bold;
        }
      }

      .manufacturers {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 20px;
        width: 100%;
        max-width: 900px;
        border: 1px solid #cfdbec;
        padding: 25px;
        border-radius: 10px;

        li {
          list-style-type: none;
          display: flex;
          align-items: center;
          gap: 5px;
          border: 1px solid #cfdbec;
          border-radius: 5px;
          padding: 3px 5px;
          cursor: pointer;
        }
      }

      button {
        width: 135px;
        height: 46px;
        background: #1a59da 0% 0% no-repeat padding-box;
        border-radius: 5px;
        border: none;
        font: normal normal normal 15px/20px "Open Sans", sans-serif;
        letter-spacing: 0px;
        color: #ffffff;
        align-self: flex-end;
        cursor: pointer;
      }
    }
  }
}

// for mobile
@include mobile() {
  .container {
    .wrapper {
      h2 {
        font-size: 22px;
        text-align: center;
      }
      .vehicles {
        margin-bottom: 20px;
        justify-content: center;
        gap: 12px;
        .tab {
          justify-content: unset;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          width: 100px;
          height: 35px;
          font: normal normal normal 13px/18px Open Sans, sans-serif;
          img {
            margin: 0px 12px;
            width: 25px;
          }
        }
      }
    }
  }
}
