.main {
    min-height: 100vh;
    border: 1px solid transparent;
    background-color: red 0% 0% no-repeat padding-box;
}

.container{
    min-height: 50vh;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrapper{
        background-color: red 0% 0% no-repeat padding-box;
    }
}

button{
    &:disabled {
        cursor: not-allowed;
      }
}