@import "../mixins";

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 89px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0px 30px;

    .right {
      display: flex;
      align-items: center;

      .logo {
        margin-left: 40px;
        cursor: pointer;
        width: 218px;
        height: 46px;
        // width: 80px;
      }

      nav {
        ul {
          display: flex;
          align-items: center;
          gap: 50px;

          li {
            list-style: none;
            font: normal normal normal 15px/20px "Open Sans", sans-serif;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer;
            transition: all 300ms ease-in-out;

            &:hover {
              color: var(--primary-color);
            }
          }

          .active {
            font: normal normal bold 15px/20px "Open Sans", sans-serif;
            color: var(--primary-color);
          }
          
        }
      }
    }

    .left {
      display: flex;
      align-items: center;

      .menu {
        display: none;
        margin-left: 10px;
      }

      .userIcon {
        margin-left: 5px;
        cursor: pointer;
      }

      .logout {
        cursor: pointer;
      }

      .btns {
        display: flex;
        align-items: center;
        gap: 15px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 161px;
          height: 35px;
          border-radius: 5px;
          border: none;
          font: normal normal normal 15px/20px "Open Sans", sans-serif;
          letter-spacing: 0px;
          cursor: pointer;

          &:nth-child(1) {
            color: #ffffff;
            background: var(--secondary-color) 0% 0% no-repeat padding-box;
          }

          &:nth-child(2) {
            border: 1px solid var(--secondary-color);
            background-color: transparent;
            color: var(--secondary-color);
          }
        }

        .menu {
          display: none;
        }
      }
    }
  }
}

// for laptops
@include laptop() {
  .header {
    .wrapper {
      height: 89px;
      padding: 0px 20px;

      .right {
        .logo {
          margin-left: 20px;
        }

        nav {
          ul {
            gap: 35px;

            li {
              font-size: 14px;
            }

            .active {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// for tablets
@include tablet() {
  .header {
    .wrapper {
      height: 75px;
      padding: 0px 20px;

      .right {
        order: 2;
        .logo {
          margin-left: -15px;
        }
        nav {
          display: none;
        }
      }

      .left {
        order: 1;
        .menu {
          display: block;
        }

        .btns {
          button {
            &:nth-child(2) {
              display: none;
            }
          }

          .menu {
            display: block;
          }
        }
      }
    }
  }
}

// for mobile
@include mobile() {
  .header {
    .wrapper {
      height: 70px;
      padding: 0px 10px;

      .right {
        order: 2;
        .logo {
          margin-left: -5px;
          width: 170px;
          height: 38px;
        }
      }

      .left {
        order: 1;
        .btns {
          button {
            display: none;
          }
        }
        .hide_on_mobile{
          display: none;
        }
      }
    }  
  }
  
}