@mixin mobile() {
  @media screen and (max-width: 540px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (max-width: 1080px) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

